<template>
  <main
    class="blank-aside-js page_bg_grey"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t("Prepaid profit percentage") }}
    </p>

    <section class="filter-part sm-content">
      <div class="form-item">
        <label>{{ $t('Category') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Card Type') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Supplier ID') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Product') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
    </section>

    <section class="sm-content mt-5">
      <label>{{ $t('Tags') }}</label>
      <div class="tag-block">
        <img src="/assets/img/icons/search-light.svg" alt="" class="search-icon" />
        <div class="tag-close_btn total-tag-close">
          <img src="/assets/img/icons/times-light.svg" alt="" />
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('Tags') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('Tags') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section class="sm-content mt-15">
      <div class="d-flex align-items-end bottom-setting">
        <div class="detail-form">
          <label>{{ $t("Customer Type") }}</label>
          <div class="mt-2">
            <input
              type="text"
              class="input"
              :placeholder="$t('Everything')"
            />
          </div>
        </div>
        <vue-slider 
          v-model="sliderValue"
          :direction="sliderDirection"
          class="cus-vue-slider"
          tooltip="always"
          :min="0"
          :max="100"
        ></vue-slider>
      </div>
      <div class="d-flex align-items-end bottom-setting mt-15">
        <div class="detail-form mb-7">
          <label>{{ $t("% profit") }}</label>
          <div class="mt-2">
            <input
              type="number"
              class="input"
              value="10"
            />
          </div>
        </div>
        <button class="primary-btn mb-7">
          {{ $t("Apply % profit to all resellers") }}
        </button>
        <button class="primary-btn bg-red3 mb-7">
          {{ $t("Remove % profit on all distributors") }}
        </button>
      </div>
      <div class="d-flex align-items-end bottom-setting mt-7">
        <div class="detail-form mb-7">
          <label>{{ $t('Points') }}</label>
          <div class="mt-2">
            <input
              type="number"
              class="input"
              value="2"
            />
          </div>
        </div>
        <button class="primary-btn mb-7">
          {{ $t("Apply gift points to all resellers") }}
        </button>
        <button class="primary-btn bg-red3 mb-7">
          {{ $t("Remove gift points on all distributors") }}
        </button>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'SCPercentage',
  data() {
    return {
      sliderValue: [0, 50]      
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-part {
  display: flex;
  justify-content: space-between;
  .form-item {
    width: calc(25% - 30px);
  }
}
.detail-form {
  width: 100%;
  max-width: 250px;
}
.bottom-setting {
  .primary-btn {
    width: unset;
    padding: 0 20px;
    margin-right: 30px;
    min-width: 350px;
    font-size: 16px;
  }
}
.cus-vue-slider {
  width: 100% !important;
  margin-right: 30px;
}

@media screen and (max-width: 980px) {
  .filter-part {
    flex-wrap: wrap;
    .form-item {
      width: 100%;
    }
  }
  .cus-vue-slider {
    margin-top: 50px;
    margin-right: 0;
  }
  .bottom-setting {
    flex-wrap: wrap;
    .primary-btn {
      width: 100%;
      margin-right: 0;
    }
  }
  .detail-form {
    max-width: unset;
  }
}

.ltr-type {
  .cus-vue-slider {
    margin-right: 0;
    margin-left: 30px;
  }
  .bottom-setting .primary-btn {
    margin-right: 0;
    margin-left: 30px;
  }
}
</style>